import { SitePage } from 'app/layout';
import { About } from 'app/about';

export default function AboutPage() {
  return (
    <SitePage>
      <About />
    </SitePage>
  );
}
