import { Avatar, Container, Grid, Box, Typography, useTheme } from '@mui/material';
import monash_logo from './imgs/monash-logo.png';
import marie from './imgs/marie.png';
import mairead from './imgs/mairead.png';
import anna from './imgs/anna.jpg';
import glenn from './imgs/glenn.jpg';
import tony from './imgs/tony.png';
import action_lab from './imgs/action-lab.png';
import { ExtLink, Heading } from 'components';
import { styled } from '@mui/system';

export function About() {
  const theme = useTheme();

  return (
    <Container
      sx={{
        marginTop: theme.spacing(8),
      }}>
      <Box mt={2} mb={4}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Heading>
              The <em>PiP</em> Story
            </Heading>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" paragraph>
              <em>Partners in Parenting</em> (PiP) aims to bridge the gap between complex research evidence and
              practical resources for parents.
            </Typography>
            <Typography variant="body1" paragraph>
              We know that parents play an important role in protecting their child’s mental wellbeing. However, despite
              extensive research supporting the role parents can play in reducing their teen’s risk of mental health
              problems, until recently this information was not easily accessible to parents.
            </Typography>
            <Typography variant="body1" paragraph>
              PiP brings together decades of high-quality research evidence and the consensus of international experts
              on parenting and youth mental health. Parents can access this information via an interactive, online
              program, designed to build skills and confidence in ways that may protect their teen from depression and
              anxiety disorders.
            </Typography>
            <Typography variant="body1" paragraph>
              To date, we’ve conducted three large research trials with over 2000 Australian parents. Our results show
              that PiP is effective, with benefits for both parents and teens.
            </Typography>

            <Typography variant="subtitle1">
              <strong>Responding to School Refusal: PiP-Ed</strong>
            </Typography>
            <Typography variant="body1" paragraph>
              We know that school reluctance and refusal problems are common in young people with depression and/or
              anxiety disorders. Based on feedback from parents who have completed previous versions of PiP, we have
              added new program content to better support parents of teens with school refusal.
            </Typography>
            <Typography variant="body1" paragraph>
              The PiP-Ed program includes two new modules (Understanding school refusal and Working together to overcome
              school refusal) with evidence-based and expert-endorsed strategies to respond effectively to school
              refusal.
            </Typography>

            <Typography>
              If you’d like to read more about the research behind PiP, click{' '}
              <ExtLink href="https://partnersinparenting.com.au/static/PiP_research_summary-fee3e952d1c2ab23630e06229784fe95.pdf">
                here
              </ExtLink>{' '}
              for a summary of published papers, or get in touch with us at{' '}
              <ExtLink href="mailto:med-pip-ed@monash.edu">med-pip-ed@monash.edu</ExtLink>.
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Heading>
              The people behind <em>PiP-Ed</em>
            </Heading>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignContent="center"
            sx={{
              order: 1,
              [theme.breakpoints.down('sm')]: {
                order: 2,
                margin: theme.spacing(2, 0),
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <MonashLogoImage src={monash_logo} height="100%" />
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 2,
              [theme.breakpoints.down('sm')]: {
                order: 1,
              },
            }}>
            <Box>
              <Typography variant="body1" paragraph>
                We are a team of researchers at Monash University, Deakin University, and the University of Melbourne,
                Australia. We are passionate about supporting parents and improving the mental wellbeing of young
                people.
              </Typography>
              <Typography variant="body1" paragraph>
                Our latest program, PiP-Ed, is made possible through a partnership between the{' '}
                <strong>Parenting and Youth Mental Health Group</strong>, Turner Institute for Brain and Mental Health,
                and <strong>Action Lab</strong>, Faculty of Information Technology. Together, we have redesigned PiP to
                improve parents’ experience of the program.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 3 }}>
            <Box>
              <Typography variant="h5">A/Prof Marie Yap</Typography>
              <Typography variant="body1" paragraph>
                Marie is an Associate Professor, Psychologist and Head of the Parenting and Youth Mental Health Group at
                Monash University. Marie has been recognised as a leading international expert in parent-child
                relationships.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 4 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={marie} sx={avatarStyle} />
              <Typography variant="h6">A/Prof Marie Yap, Monash University</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Lead Researcher & Founder of Partners in Parenting{' '}
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 5,
              [theme.breakpoints.down('sm')]: {
                order: 6,
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={mairead} sx={avatarStyle} />
              <Typography variant="h6">Dr Mairead Cardamone-Breen, Monash University</Typography>
              <Typography variant="subtitle2" color="textSecondary">
                Development and Evaluation
              </Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            sx={{
              order: 6,
              [theme.breakpoints.down('sm')]: {
                order: 5,
              },
            }}>
            <Box>
              <Typography variant="h5">Dr Mairead Cardamone-Breen</Typography>
              <Typography variant="body1" paragraph>
                Mairead is a Psychologist and Research Fellow in the Parenting and Youth Mental Health Research Group at
                Monash University. Mairead has been instrumental in the development and evaluation of the Partners in
                Parenting program.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 7 }}>
            <Box>
              <Typography variant="h5">A/Prof Glenn Melvin</Typography>
              <Typography variant="body1" paragraph>
                Glenn is an Associate Professor & clinical psychologist with extensive academic and clinical expertise
                in adolescent depression, suicide, and school refusal and attendance problems. He is a founder of the
                International Network for School Attendance. Glenn has supervised the development of the latest PiP-Ed
                program.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 8 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={glenn} sx={avatarStyle} />
              <Typography variant="h6">A/Prof Glenn Melvin, Deakin University</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            sx={{
              order: 9,
              [theme.breakpoints.down('sm')]: {
                order: 10,
              },
            }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={anna} sx={avatarStyle} />
              <Typography variant="h6">Anna Smout</Typography>
            </Box>
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            container
            alignItems="center"
            sx={{
              order: 10,
              [theme.breakpoints.down('sm')]: {
                order: 9,
              },
            }}>
            <Box>
              <Typography variant="h5">Anna Smout</Typography>
              <Typography variant="body1" paragraph>
                Anna is completing her PhD in Clinical Psychology at Monash University with the Parenting and Youth
                Mental Health Group. Anna’s PhD research focuses on how we can support parents of teenagers with school
                reluctance and refusal difficulties, including co-developing the PiP-Ed program.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 11 }}>
            <Box>
              <Typography variant="h5">Prof Tony Jorm</Typography>
              <Typography variant="body1" paragraph>
                Tony is a Professor Emeritus at the University of Melbourne and an NHMRC Leadership Fellow. His research
                focuses on public knowledge and beliefs about mental illness, and interventions to improve the public’s
                helpfulness towards people developing mental illness. Tony has been a key contributor to all PiP
                projects to date.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 12 }}>
            <Box sx={avatarBoxStyle}>
              <Avatar src={tony} sx={avatarStyle} />
              <Typography variant="h6">Prof Tony Jorm</Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} container alignItems="center" style={{ order: 13 }}>
            <Box>
              <Typography variant="h5">Action Lab</Typography>
              <Typography variant="body1" paragraph>
                Action Lab is a multidisciplinary team of impact-focused researchers in the department of Human-Centred
                Computing at Monash University, Australia. They recently joined the PiP team to co-design the most
                recent versions of PiP, with the latest technology and their domain expertise in computer science,
                human-centred design methods, interaction design and digital mental health.
              </Typography>
              <Typography variant="body1" paragraph>
                The Action Lab project team is led by Professor Patrick Olivier and includes a highly energetic and
                creative team of researchers and research engineers: Dr Tom Bartindale, Dr Jue (Grace) Xie, Dr Roisin
                McNaney, Joshua Seguin, Harrison Marshall and Dr Ling Wu.
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} style={{ order: 14 }} container alignContent="center">
            <Box sx={avatarBoxStyle}>
              <ActionLogoImage src={action_lab} height="100%" />
            </Box>
          </Grid>
        </Grid>

        <Box mt={2} mb={4}>
          <Box mb={4}>
            <Heading>Acknowledgements</Heading>
          </Box>

          <Typography variant="body1" paragraph>
            The <em>Partners in Parenting</em> program is part of a suite of parenting resources from the{' '}
            <ExtLink href="https://www.parentingstrategies.net">
              <em>Parenting Strategies Program</em>
            </ExtLink>
            , which was first established in 2010. Throughout its 10-year history, the program has had many contributors
            and funders, whom we cannot all acknowledge by name here. Nonetheless, special thanks go to the following
            contributors to the <em>Partners in Parenting</em> program: Professor Tony Jorm (University of Melbourne),
            Dr Katherine Lawrence (Monash University), Professor Ron Rapee (Macquarie University), Jacqueline Green,
            Clare Nowell (Monash University), and all the staff, students, and volunteers of the Parenting and Youth
            Mental Health Group from 2014 to 2022.
          </Typography>

          <Typography variant="body1" paragraph>
            We also thank the parents and young people who have contributed feedback throughout the different stages of
            the PiP journey.
          </Typography>

          <Typography variant="body1" paragraph>
            We acknowledge with thanks the funding which have supported the development and evaluation of the program
            from 2011-2021, including: National Health and Medical Research Council (APP IDs 2005621, 566652, 1061744,
            400001), Australian Rotary Health, Monash University, and Helen Macpherson Smith Trust.
          </Typography>
        </Box>
      </Box>

      <Box mt={2} mb={4}>
        <Typography variant="body1" color="textSecondary" align="center" paragraph>
          This work is licensed under the Creative Commons Attribution-ShareAlike 4.0 International License. To view a
          copy of this license, visit <ExtLink href="https://creativecommons.org/licenses/by-sa/4.0/">here</ExtLink> or
          send a letter to Creative Commons, PO Box 1866, Mountain View, CA 94042, USA.
        </Typography>
      </Box>
    </Container>
  );
}

const avatarBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'center',
};

const avatarStyle = {
  width: 200,
  height: 200,
};

const MonashLogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '70%',
  },
  [theme.breakpoints.down('md')]: {
    width: '100%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '90%',
  },
}));

const ActionLogoImage = styled('img')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    width: '60%',
  },
  [theme.breakpoints.down('md')]: {
    width: '90%',
  },
  [theme.breakpoints.down('sm')]: {
    width: '80%',
  },
}));

const PuLogo = styled('img')(({ theme }) => ({
  width: '350px',
  height: '110px',
  [theme.breakpoints.down('md')]: {
    width: '250px',
    height: '78px',
  },
  [theme.breakpoints.down('sm')]: {
    width: '200px',
    height: '64px',
  },
}));
